h4 {
     background-color: rgba(255, 255, 255, 0.6);
     margin-bottom: 1px;
    width: 100px;
    padding: 12px;
    z-index: 2;
    border-radius: 3px;
    
}

.gallery-row{
    z-index: 0;

}

.buttons-container {
    display: flex;
    margin-top: 20px;
  
}