.container {
    background-color: rgba(255, 255, 255, 0.6);
    position: relative;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: -30px;
    max-width: 100vw;
    // border: 1px solid red;
    // width: 900px;
    height:300px !important;


    @media screen and (max-width: 768px) {
        height:220px !important;
    }

    @media screen and (max-width: 600px) {
        height:160px !important;
    }

    #title {
        // background-color: rgba(255, 255, 255, 0.6);
         margin-left: 30px;
         margin-bottom: 0px;
         font-size: 1.4rem
    }

    .gallery-row-container {
   
    display: flex;
    flex-direction: row;
    //  overflow: hidden;
     flex-wrap: nowrap;
    // border: 3px solid rgba(154, 205, 50, 0.7);
    border-radius: 6px;
    height: 250px;
    margin-bottom: 0px;
    padding: 1% 1%;
    transition: transform 300ms ease 100ms;



    @media screen and (max-width: 768px) {
        height: 120px !important;
        width: 120px !important;
    
    }

    @media screen and (max-width: 600px) {
        height: 120px !important;
        width: 120px !important;
    
    }

    &:hover {
        // border: 6px solid rgba(192, 35, 216, 0.7);
        // border-radius: 6px;
        // overflow: visible;
        // height: 250px;
    }

    .row-title {
        background-color: lightgreen;
        padding: 10px;
        padding-top: 10px;
    }




}



.button-backward {
    position: absolute;
   
    top: 0px;
    left: 10px;
    width: 150px;
    background-color: rgba(102, 205, 170, 0.6);
    color: white;
    border-radius: 0px;
    font-size: 40px;
    
    // &.hide{
    //     visibility: hidden;
    // }
    // &.show{
    //     visibility: visible;
    // }

    &:hover {
        background-color: rgba(232, 115, 232, 0.7);
        color: white;
    }

    &:active {
        background-color: rgba(232, 115, 232, 0.7);
        color: white;
    }

    &:focus {
       background-color: rgba(102, 205, 170, 0.7);
        color: white;
    }






    @media screen and (max-width: 768px) {
        position: absolute;
        height:180px !important;
        width: 40px !important;
        left: 0px;
        font-size: 10px;
        margin-top: 20px;
    }

    @media screen and (max-width: 600px) {
        position: absolute;
        height:140px !important;
        width: 30px !important;
        left: 0px;
        font-size: 10px;
        margin-top: 5px;
    }

    .arrow{
        position: absolute;
        left: 35px;
        top: 120px;
        color: rgba(250,250,250,1.0) !important;
        font-size: 40px;
    
        @media screen and (max-width: 768px) {
            font-size: 30px;
            top: 80px;
            left: 5px;
        }
    
    
        @media screen and (max-width: 600px) {
        
            font-size: 20px;
            top: 60px;
            left: 2px;
        }
    }
}


.button-forward {
    position: absolute;
    width: 150px;
    top: 0px;
    right: 0px;
    background-color: rgba(102, 205, 170, 0.3);
    color: white !important;
    border-radius: 0px;
    font-size: 40px;
    
    
    // &.hide{
    //     display: none;
    // }
    // &.show{
    //     display: inline-block;
    // }

    &:hover {
        background-color: rgba(232, 115, 232, 0.4);
        color: white;
        // display: block;
    }

    &:active {
        background-color: rgba(232, 115, 232, 0.9);
        color: white;
    }

    &:focus {
       background-color: rgba(152, 105, 232, 0.9);
        color: white;
    }




    @media screen and (max-width: 768px) {
        position: absolute;
        height:180px !important;
        width:40px !important;
        right: 0px !important;
        font-size: 10px;
        margin-top: 20px;
    }

    @media screen and (max-width: 600px) {
        position: absolute;
        height:140px !important;
        width:30px !important;
        right: 0px !important;
        font-size: 10px;
        margin-top: 5px;
    }

}

.arrow{
    position: absolute;
    right: 35px;
    top: 120px;
    color: rgba(250,250,250,1.0) !important;
    font-size: 40px;

    @media screen and (max-width: 768px) {
        font-size: 30px;
        top: 80px;
        right: 5px;
    }


    @media screen and (max-width: 600px) {
    
        font-size: 20px;
        top: 60px;
        right: 2px;
    }
}

}