.user-page {
    // background-color: rgba(248, 243, 250, 0.7);
    background-color: rgba(248, 243, 250, 1.0);
    // border: solid 1px darkgreen;
    // border-radius: 10px;
    padding: 1.2em;
    

    .log-out {
        background-color: darkgreen;
        max-width:120px;
        width: 15%;
        padding: 10px 20px;
        margin-left: 75%;
        border-radius: 6px;
        color: white;
    }



    .user-content {

        margin-top: -80px;

        @media screen and (max-width:600px){
            margin-top: -20px;
        }



    .greeting-block {
        font-family: 'Martel', serif; 
        // background-color: rgba(16, 85, 108, 0.9);
        // background-color: rgba(248, 243, 250, 1.0);
        // color: rgb(16, 85, 108);
        padding: 20px;
        padding: 0.1em;
        width: 400px;
        // border-bottom: 1px solid rgb(71, 71, 71);
        border-radius: 3px 3px 0px 0px;


        @media screen and (max-width:600px){
            width: 400px;
            margin-left: -40px;
        }

        .user-image {
            width: 60px;
            display: inline-block;
            margin-left: 20%;
            margin-top: 10px;
       
            .avatar {
                width: 40px;
            }
        }


        .greeting-text {
            // background-color: rgb(255, 238, 217);
            padding: 1.4em;

            @media screen and (max-width:600px){
                width: 400px;
                margin-left: -40px;
                padding: 0.8em;
            }

        .name {
            // color: red;
            margin-left: 1em;
        }
        }



    }


    .to-do-block {
        font-family: 'Martel', serif; 
        background-color: rgba(220, 241, 247, 1.0);
        background-color: rgba(228, 230, 230, 1.0);
        // background-color: rgb(201, 163, 112);
        padding: 0.2em;
        border-top: 1px solid rgb(71, 71, 71);
        border-bottom: 10px solid rgb(79, 47, 19);
        border-bottom: 10px solid rgb(244, 244, 69);

        // border-left : 3px solid rgb(79, 47, 19);
        // border: 3px solid rgb(79, 47, 19);
        // border-radius: 0px 3px 0px 0px;






    }

    .progress-block {
        font-family: 'Martel', serif; 
        background-color: lightpink;
        
        padding: 2em;

        .your-progress {
            //background-color: rgb(8, 202, 245);
            color: white;
            color: rgb(8, 202, 245);
            color: black;
            
            padding: 0.6em 1.2em;
         
            
            margin-left:-0.8em;
            margin-top: -0.6em;
            margin-bottom: -0.6em;
            width: 200px;
            border-radius: 3px;
        }

        .user-works {
            transform: scale(0.66);
            margin-left: -30%;
            margin-top: -0.6em;
            width: 160%;
        }

        .user-messages {
            margin-left: -0.6em;
            border-bottom: 1px solid black;
        }

        .user-critMessages {
            margin-left: -0.6em;
            margin-top: 0.2em;
        }

        .user-assignments-list {
            margin-top: 1.2em;
        }
        .user-missing-assignments-list {
            margin-bottom: 40px;
        }
        .user-random-submissions{
            margin-top: 20px !important;
        }

        .random {
            // margin-left: -0.6em;
            margin-top: 20px;

            .random-button {
                margin-top: 0.8em;
            }
        }

        .user-gallery {
            background-color: white;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 50px;
            margin-bottom: 30px;
        }

        .feedback-block {
            background-color: white;
            padding: 50px;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 30px;
            &.message-container{
                margin-top: 20px;
                width: 90%;
                margin-top: 12px;
                border-bottom: 1px solid grey;
                padding: 12px;
            }
        }
        
        .user-discussion-block {
            background-color: white;
            padding: 50px;
        }

    }
    }


}