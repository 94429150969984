.messages {
    
    height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .message-container {
        width: 90%;
        margin-top: 6px;
        border-bottom: 1px solid grey;
        padding: 12px;
    }
}