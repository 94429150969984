.unit-page {
    // background-color: black;
    border: 5px solid rgba(154, 205, 50, 0.7);
    border-radius: 12px;
    margin-left:5%;
    margin-top:20px;
    line-height: 100%;
    width: 90%;
    height: 1000px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;

    &:hover {
        // cursor: pointer;
    
        & .background-image {
          transform: scale(1.1);
          transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }
    
        & .content {
          // opacity: 0.9;
        }
      }
    .background-image { 
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        // opacity:0.95;
        filter: brightness(0.9);
      }

      .content {
        // background-color: black;
        // width: 78%;
        height: 90px;
        padding: 0 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: left;
        // justify-content: left;
        border-radius: 6px;
        // border: 1px solid black;
        // background-color: white;
        // opacity: 0.7;
        position: absolute;
        color: white; 

        




      .header-block {
        background-color: rgba(0,0,0,0.8);
        background-color: rgba(80, 10, 110, 0.8);
        width: 40%;
        border-radius: 6px;
        margin: 25px 0;
        border: 1px solid white;
        
        

        &.unit1 {
          background-color: rgba(84, 211, 103, 0.8);
          background-color: mediumaquamarine;
          color: white;
      }
  
      &.unit2 {
          background-color: rgba(250, 250, 210, 0.9);
          color: black;
      }
  
      &.unit3 {
          background-color: rgba(72, 15, 72, 0.8);
          color: white;
      }
  
      &.unit4 {
          background-color: rgba(18, 75, 94, 0.9);
          color: white;
      }



        
        .title {
             
          margin: 20px 10px 10px 20px;
          width: 70%;
          padding:5px;
          border-bottom: 1px solid rgba(255,255,255,0.3);
          
        }


      }  



      .overview {
          // background-color: rgba(30, 29, 29, 0.4);
          font-size: 16px;
          // color: lightgray;
            margin: 2%;
            width: 90%;
            padding: 1.2em;
            border-radius: 3px;;
      }
      .dates-block {
        // background: lightgray;
        // border: 2px solid black;
        width: 35%;
        padding: 14px;
        margin-left: 10%;
        margin-top: 20px;
        border-radius: 6px;
        font-family: 'Lato', sans-serif;

        .dates-container {
          background-color: rgba(0,0,0,1.0);
          background-color: rgb(223, 29, 126);
          color: white;
          border: 1px solid white;
          border-radius: 6px;
          padding: 10%;
          min-height: 500px;
          
        }
      }

          
        .teaser-block {
          background-color: rgb(255, 182, 193);
          background-color: whitesmoke;
          // font-family: 'Open Sans Condensed', sans-serif;
          color: rgba(0, 0, 0, 1.0);
          // font-weight: bold; 
          width: 100%;
          padding: 0.5em;
          border-radius: 2px;
          // border: 1px solid rgb(223, 29, 126);
          display: flex;
          flex-direction: column;
          align-items: center;
          // margin-left: 5%;
          margin-bottom: 10%;
          margin-top: 2%;

          &:hover {
            background-color: rgb(223, 29, 126);
            color: white;
          }


          .assignment-title {
            background-color: lightgray;
            width: 90%;
            padding: 12px;
            margin: 0px;
            margin-bottom: 20px;
            display: inline-block;
            border-radius: 6px;
            // border: 1px solid black;
          }
          

      }

      .objectives-block {
        background-color: lightseagreen;
        border: 1px solid white;
        width: 85%;
        // height: 100px;
        padding: 20px;
        margin-top: 25px;
        border-radius: 6px;
      }

        .objectives {
           background-color: rgba(11, 7, 77, 0.3);
           padding: 2em;
           border-radius: 6px;
          width: 95%;
          margin-left: 2.5%;
        }




      
      
      }
      


    


      



}