.next-assignment-block {

    // background-color: rgba(145, 255, 49, 1.0);
    padding: 0.4em;
    margin: 0.2em;
    border-radius: 3px;
    color: rgb(16, 85, 108);
    color: black;

    .next-assignment-text {
        // background-color: rgb(255, 238, 217);
        width: 98%;
        padding: 0.6em;
        // border-radius: 6px;
        border-top: 1px solid  rgba(16, 85, 108, 0.5);
        // border-bottom: 1px solid grey;
    }

}