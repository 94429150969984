.day-link-item {
    // background-color: rgb(57, 54, 54);
    margin: 2px;
    padding: 4px;
    color: white;
    font-size: 16px;
    // height: 20px;
    width: 90%;
   
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid black;

    &:hover{
        cursor: pointer;
        background-color: darkgrey;
        color: red;
    }

    a {
         color: white;

        &:hover{
            color: red;
            // padding:6px;
        }

    }
    
}

