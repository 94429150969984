
.comment-text {
    font-family: 'Martel', serif;
    font-size: 18px; 
}

// .comment {
//     border: 2px solid red;
//     color: red;
//     font-size: 48px;
// }