.resource-container div{
    font-family: 'Martel', serif; 
    background-color: rgb(198, 255, 242);
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin-bottom: 1em;
    flex-wrap: wrap;
    border-radius: 6px;
    border: 1px solid white;

    .sections {
       

        .section div{
            background-color: grey;
           
            width: 30%;
            margin-top: 1em;
            margin-left: 1em;
            border-radius: 6px;
            display: inline-block;
         



        }

    }
}