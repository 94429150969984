.project-page-container {
    background-color: white;
    display: flex;
    padding: 2em;
    width: 90%;
    margin-left: 5%;
    border-radius: 6px;

    &.unit1 {
        background-color: mediumaquamarine;
        color: white;
    }

    &.unit2 {
        background-color: yellow;
        color: black;
    }

    &.unit3 {
        background-color: purple;
        color: white;
    }

    &.unit4 {
        background-color: lightblue;
        color: black;
    }




    .content {
        
        padding: 2em;
        width: 60%;
        border-radius: 6px;
        border: 1px solid white;

        &.unit1 {
            background-color: rgba(21, 61, 23, 0.6);
            color: white;
        }
    
        &.unit2 {
            background-color: lightgoldenrodyellow;
            color: black;
        }
    
        &.unit3 {
            background-color: rgb(72, 15, 72);
            color: white;
        }
    
        &.unit4 {
            background-color: rgb(18, 75, 94);
            color: white;
        }




    }

    .links {
        width: 40%;
        color: black;

        .links-list {
            flex: 1 2 auto;
            font-size: 14px;
            // width: 60%;
            list-style-type: none;
            background-color: lightyellow;
            padding: 10px;
            margin-left: 20px;
            border: 1px solid black;
            border-radius: 6px;
    
            .day-overview-link {
                padding-left: 6px;
                
                
                &:hover {
                background-color: grey;
                padding: 10px;
                border-radius: 6px;
               
                a{
                    color: white;
                    padding: 1.2em;
                }
                
                }
            }

        }
    }
}