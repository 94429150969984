// .sign-in-page {
//     width: 850px;
//     display: flex;
//     justify-content: space-between;
//     margin: 30px auto;
// }

.sign-in-page {
    background-color: white;
    border: solid 1px darkgreen;
    border-radius: 10px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding: 50px;
}