.assignment-page-container {
    background-color: white;
    padding: 40px 100px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    width: 100%;


    blockquote {
        background-color: lightgoldenrodyellow;
        padding: 1.5em;
    }

    code {
        // background-color: lightgray;
        padding: 1.5em;
        // margin-left: -40px;
    }

    img {
        margin: 40px 0px;
    }

    a {
        background-color: lightseagreen;
        padding: 12px;
        border-radius: 6px;
        color: red;
    }

    .edit-error {
        background-color: lightpink;
        color: white;
        padding: 2em;
    }
}

// p {
//     margin: 20px 20px 20px 20px;
// }

