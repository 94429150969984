.day-directory-container {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 100%;
    // margin-left: -20px;
    margin-top: -10px;
    margin-bottom: 10px;
    justify-content: space-evenly;
    // border:1px solid red;

    .item {
        margin-left: 2px;
        border: 1px solid grey;
        border-radius: 6px;
        width: 5%;

    &.selected {
        border: 2px solid red;
    }    

    &.green {
        background-color: lightgreen;
    }    

    &.yellow {
        background-color: yellow;
    }  
    
    &.purple {
        background-color: purple;
    }  
    &.pink {
        background-color: lightblue;
    }  

    &:hover {
        background-color: lightpink;
        transform: scale(1.1, 1.1);
        color: black;
        padding-right: 2px;
        margin-left: 2px;
        margin-right: 2px;
        cursor: pointer;
    
    }

    }
}