.status {
    // background-color: rgba(179, 59, 151, 0.8);
    color: white;
    padding: 0.1em;
    margin: 0.1em;
    border-radius: 3px;

    .text-container {
        // background-color: rgb(255, 238, 217);
        color:  rgb(179, 59, 151);
        color: red;
        color: rgb(16, 85, 108);
        color: black;
        padding: 0.8em;
        border-radius: 3px;
    }
}