.block {
    font-family: 'Open Sans', sans-serif;
    padding: 6px;
    width: 85%;
    margin-left: 5%;
    
}

.welcome-link {
    color: red;
}
