.missing-assignment-list-item-container{
    // background-color: lightgrey;
    color:  red;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    max-width: 300px;
    // height: 60px;
    border-radius: 6px;

    border: 2px solid lightgrey;
    
    &:hover{
        background-color: lightgrey;
        cursor: pointer;
    }

}