
.show-link {
    background-color: lightseagreen;
    padding: 6px 12px;
    border-radius: 6px;
    color: rgb(12, 53, 22);
    margin-right:5px;

    &:hover{
        background-color: #224d4d;
        color: white;
    }
}


.final-show-intro {

    margin-left:40px;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    height: 420px !important;
    width: 400px !important;
    border-radius: 6px;
    padding:50px;

    @media screen and (max-width: 700px) {
        width: 96%;
        padding: 10px;
        margin-left:2%;
    }


    h1 {
        margin-top: 0px;
        width: 100%;
        text-justify: center;
        // font-family: 'Raleway', sans-serif;
    }
    h2 {
        margin-top: -10px;
        background-color: rgba(255, 255, 255, 0.0);
        width: 100%;
        text-justify: center;
        // font-family: 'Raleway', sans-serif;
    }

    p {
         font-family: 'Martel', serif;
         font-size: 1.2rem;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: left;

    @media screen and (max-width: 700px) {
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }

}

.final-show-description {
    margin-left:40px;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    height: 420px !important;
    width: 400px !important;
    border-radius: 6px;
    padding:50px;

    @media screen and (max-width: 700px) {
        width: 96%;
        padding: 10px;
    }
}

.guest-book {
    margin-left:40px;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    height: 420px !important;
    width: 400px !important;
    border-radius: 6px;
    padding:50px;

    @media screen and (max-width: 700px) {
        width: 96%;
        padding: 10px;
    }


}


.final-works-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    // width: 100vw;

}