.directory-item {
    min-width: 10%;
    height: 90px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 6px;
    margin: 0 7.5px 15px;
    overflow: hidden;
    // filter: brightness(0.3);

    &.selected {
      filter: brightness(1.0);
    }

    &.current {
      border: solid 3px red;
    }

    &:hover {
        cursor: pointer;
      
        & .background-image {
          transform: scale(1.1);
          transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }
      
        & .content {
          opacity: 0.9;
        }
      }
    & .large {
        height: 380px;
    }

    &:first-child {
      margin-right: 7.5px;
    }
  
    &:last-child {
      margin-left: 7.5px;
    }
  
    .background-image {
        width: 100%;
        height: 100%;
        min-width:60px;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
        

    }

    .content {
      height: 60px;
      width: 8%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border: 15px solid rgb(224, 109, 15);
      background-color: white;
      opacity: 0.9;
      position: absolute;
      border-radius: 6px;
      color: black;

      &.unit1 {
        background-color: mediumaquamarine;
        color: white;
    }

    &.unit2 {
        background-color: yellow;
        color: black;
    }

    &.unit3 {
        background-color: purple;
        color: white;
    }

    &.unit4 {
        background-color: lightblue;
        color: black;
    }





      &:hover {
        cursor: pointer;
        background-color: black;
        color: white;
      }
      
  
      .title {
        font-weight: bold;
        margin-bottom: 6px;
        font-size: 14px;
        
      }
  
      .subtitle {
        font-weight: lighter;
        font-size: 10px;
      }
    }
  }
  