.syllabus-overview-container {
    background-color: white;
    border: 5px solid rgba(154, 205, 50, 0.7);
    border-radius: 6px;
    padding: 0px;
    width: 85%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 800px) {

        width: 98%;
        margin-left: 1%;

    }



    .content {
        padding: 0 3%;
    }
}