.work-panel-container {
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    margin-right: 0px;
    max-width: 600px;
    @media screen and (max-width:600px){
        width: 95%;
        // margin-left: 35px;
        // margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
    img {
        max-width: 600px;
        width: 90%;

       @media screen and (max-width:600px){
        width: 95%;
    }
    }

    .work-label {
        margin-bottom: 2px !important;
    }

    .work-link {
        // margin-top: -10px !important;
        color: blue;
        margin-bottom: 22px !important;
        display: inline-block

        :hover {
            color: red;
        }

    }
}