.meetings-container {
    background-color: white;
    padding: 70px;

    .meetings {
        margin-top: 20px;
        width: 700px;

        .name {
            width: 15%;
            display: inline-block;
        }

        .timer {
            width: 15%;
             margin-left: 50px; 
             display: inline-block;
        }
        .running {
            color: red !important;
            font-size: 30px !important;
        }

        .expired {
            color: white !important;
            background-color: red;
        }
    }

    .no-meetings {
        margin-top: 20px;
    }

}
