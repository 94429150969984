.student-container{
    width: 125px;
    max-width: 125px !important;
    max-height: 125px !important;
    height: 125px;
    margin-bottom: 40px;
    margin-left: 20px;
    display: inline-block;
    font-size: 12px;

    img {
        width: 100%;
        height: 100%;
    }
}