.comment-block {
    overflow-x: 'hidden';
    padding: 1.2em;
    
    &.selected {
        background-color: lightgrey;
    }
    

    .comment-text {
        font-family: 'Martel', serif;
        font-size: 18px;
        overflow-x: 'hidden';
        max-width: 100%;
    }
}

