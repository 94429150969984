.student-page {
    // background-color: rgba(248, 243, 250, 0.7);
    background-color: rgba(248, 243, 250, 1.0);
    // border: solid 1px darkgreen;
    // border-radius: 10px;
    padding: 1.2em;

    .student-content {

        margin-top: 0px;

        @media screen and (max-width:600px){
            margin-top: -20px;
        }



    .greeting-block {
        font-family: 'Martel', serif; 
        // background-color: rgba(16, 85, 108, 0.9);
        background-color: rgba(248, 243, 250, 1.0);
        // color: rgb(16, 85, 108);
        padding: 20px;
        padding: 0.1em;
        width: 400px;
        // border-bottom: 1px solid rgb(71, 71, 71);
        border-radius: 3px 3px 0px 0px;


        @media screen and (max-width:600px){
            width: 60%;
            margin-left: 10%;
        }

        .user-image {
            width: 60px;
            display: inline-block;
       
            .avatar {
                width: 40px;
            }
        }


        .greeting-text {
            // background-color: rgb(255, 238, 217);
            padding: 1.4em;
            @media screen and (max-width:600px){
                background-color: rbga(255, 255, 255, 0.0);
                width: 60%;
                margin-left: 10%;
            }

        .name {
            // color: red;
            margin-left: 1em;
        }
        }



     }

     .user-gallery {
        background-color: white;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 50px;
    }

    }

    .user-missing-assignments-list{
        margin-top: 20px;
    }


    .user-random-number{
        margin-bottom: 20px;

    }

}