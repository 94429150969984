
.pumpkin-container {
    margin-top: 40px;


    .pumpkin {
        margin-top: 40px;
        width: 200px;
        height: 200px;
    
        img {
            width: 100%;
            height: 100%;
        }
    }
}
