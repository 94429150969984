.my_header {
    font-family: 'Open Sans Condensed', sans-serif;
    // font-family: 'Public Sans', sans-serif;
  background-color: rgba(190,20,170,0.05);
  height: 50px;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  border-radius: 0 0 10px 10px;
  border-bottom: yellowgreen 1px solid;
  border-left: yellow 1px solid;
  border-right: darkgreen 1px solid;
  font-size: 12px;

  .logo-container {
    height: 100%;
    width: 150px;
    padding: 15px 15px;
    // padding: 25px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      // background-color: rgba(153, 205, 50, 0.8);
      border-radius: 6px;
      margin: 0 0.7em;

      color: black;
      padding: 0.5em 1em;
      opacity: 0.9;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 105, 180, 0.5);
        color: rgba(153, 205, 50, 1.0);
        color: white;
      }
    }

    .login {
      background-color: rgba(153, 205, 50, 0.8);
      margin: 0 10px;
      border-radius: 6px;
      border: 1px solid red;
      color: black;
      padding: 8px 15px;
      opacity: 0.9;
      cursor: pointer;
      
      &:hover {
        background-color: rgba(255, 105, 180, 0.5);
        color: rgba(153, 205, 50, 1.0);
        color: white;
      }
    
    }


  }
}
