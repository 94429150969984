body {
    background-color: white;
}

.about-body {

        background-color: white;
        border: solid 1px darkgreen;
        border-radius: 10px;
        width: 80%;
        margin: 2% 10%;

}

p {
    line-height: 200%;
    font-size: 18px; 
}

a {
    color: red;
}