.assignment-body {
    background-color: rgb(220, 135, 220);
    color: white;
    padding: 1.6em;
    width: 80%;
    margin-top: 1.6em;
    margin-bottom: 1.2em;

    @media screen and (max-width:600px){
        width: 100%;
    }

    // border-radius: 6px;
    // border: 1px solid rgba(16, 85, 108, 0.5); 
}

img {
    max-height: 300px;
}

.assignment-button-alt-text {
    color: rgb(87, 82, 82);
}