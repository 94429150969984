
.book-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;

    h4{
        width: 100%;
        margin-bottom: 12px;
    }

}




.book-header{
    background-color: rgba(255,255,255,0.6);
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 50px;
    max-width: 400px;
}

