
.assignment-list-container {


    margin-top: 20px;
    margin-bottom: 20px;

    .assignment-list-item{
        // background-color: grey;
        color:  rgb(28, 35, 28);
        padding: 12px;
        margin-top: 10px;

        margin-left: 10px;
         display: inline-block;
    
    }
}

