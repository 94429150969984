.work-container {
    // width: 180px;
    // height: 180px;
    margin-left:40px;
    margin-bottom: 40px;
    height: 200px !important;
    width: 200px !important;

    @media screen and (max-width: 768px) {
        height: 140px !important;
        width: 140px !important;
        margin-left:5px;
    }

    @media screen and (max-width: 600px) {
        height: 80px !important;
        width: 80px !important;
        margin-left:5px;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.6);
        transform: scale(1.2) !important;
    }
    
    // &:hover .heart-icon {
    //     display: block;
    //     transform: translateX(20px);
    //     z-index: 10;
    // }


    .work-title {
        width: 180px !important;;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;


        @media screen and (max-width: 768px) {
            height: 120px !important;
            width: 120px !important;
            font-size: 0.8rem;
        }

        @media screen and (max-width: 600px) {
            height: 80px !important;
            width: 80px !important;
            font-size: 0.8rem;
        
        }
    }

    .work-image {
        width: 100%;
        height: 100%;
        min-width:60px;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
    }
}

.heart-icon {
    display: none;


}