.syllabus-day-item {
    
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        margin-left: 6px;
        // width: 90%;
        height: 80%;
        // padding: 1%;
        padding: 2%;
        border-bottom: 1px solid black;


        &:hover {
            cursor: pointer;
            background-color: white;
            color: red;
            // border-radius: 6px;
            
          }

    
}