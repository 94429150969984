.discussion-thread-container {
    overflow-y: scroll;
    overflow-x: 'hidden';
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
     font-size: 1.2rem; 
     margin-top: 0.3em !important;
      margin-left: -1.2em !important; 
      height: 85vh;

      .threaded-messages {
        overflow-x: 'hidden';
        max-width: 100%;
      }

}