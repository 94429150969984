.loading-container{
    margin-top: 300px;
    margin-left: -100px;
    width: 800px !important;
}



.discussion-messages-container {
   
    overflow-y: scroll;
    overflow-x: 'hidden';
    display: flex;
    flex-direction: column;
    background: #FFF;
     font-size: 1.2rem; 
     margin-top: -1.6em !important;
      margin-left: -1.2em !important; 
      height: 85vh;

      .discussion-message-header {
        margin-bottom: -0.6em !important;
      }



    .discussion-message-form {
        margin-top: -0.2em !important;
    }



    .discussion-messages {

        margin-top: -2.2em;
       
        


        .message-container {
             margin-bottom: 0.3em;
            padding: 0.6em;
            border-bottom: solid 1px grey;
            // overflow-x: 'hidden';
        }
        
    }

    .discussion-message-form {
        position: fixed !important;
        top: 1em;
    }


}


