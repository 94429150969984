.final-work-container {
    
    margin-left:40px;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    height: 420px !important;
    width: 400px !important;
    border-radius: 6px;
    padding-bottom:2%;

    // @media screen and (max-width: 768px) {
    //     // height: 300px !important;
    //     // width: 300px !important;
    //     // margin-left:5px;
    // }

    @media screen and (max-width: 700px) {
        width: 96%;
        // height: 400px;
        // width: 90vw;
        // margin-left: 10vw;
        margin-top: 20px;
        margin-left: 2%;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.9);
        // border: 2px solid #ccc;
        transform: scale(1.02) !important;
    }
    
    // &:hover .heart-icon {
    //     display: block;
    //     transform: translateX(20px);
    //     z-index: 10;
    // }


    .final-work-title {
        width: 94% !important;;
        margin-left: 4%;
        margin-top: 2%;
        padding-top:2%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-top: 1px solid black;

        // @media screen and (max-width: 768px) {
        //     height: 120px !important;
        //     width: 120px !important;
        //     font-size: 0.8rem;
        // }

        @media screen and (max-width: 600px) {
            // height: 80px !important;
            // width: 80px !important;
            // font-size: 0.8rem;
        
        }
    }

    .final-work-artist {
        width: 94% !important;;
        margin-left: 4%;
        margin-top: 2%;
        margin-bottom: 2%;
        padding-top:2%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: strong;
        // border-top: 1px solid black;

        // @media screen and (max-width: 768px) {
        //     height: 120px !important;
        //     width: 120px !important;
        //     font-size: 0.8rem;
        // }

        @media screen and (max-width: 600px) {
            // height: 80px !important;
            // width: 80px !important;
            font-size: 0.8rem;
        
        }
    }

    .final-work-image {
        width: 96%;
        height: 96%;
        margin-left: 2%;
        margin-top: 2%;
        min-width:60px;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
    }
}

.heart-icon {
    display: none;


}