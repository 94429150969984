.syllabus-unit-block {
    
    border: 5px solid rgba(154, 205, 50, 0.7);
    border-radius: 12px;
    margin-left: 6%;
    margin-bottom: 20px;
    line-height: 100%;
    width: 40%;
    min-width: 300px;
    min-height: 600px;
    display: flex;
    overflow: hidden;

    @media screen and (max-width: 800px) {
      margin-left: 1%;
        width: 88%;
    }

    &:hover {
        // cursor: pointer;
    
        & .background-image {
          transform: scale(1.1);
          transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }
    
        & .content {
          opacity: 0.9;
        }
      }
    .background-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        // opacity:0.95;
        filter: brightness(0.5);
      }
      .content {
         max-width: 20%;
         min-width: 200px;
         margin-left: 8%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
         
         
        // opacity: 0.7;
        position: absolute;
        color: white; 

        @media screen and (max-width: 800px) {
          max-width: 57%;
            margin-left: 10%;
            width: 88%;
        }




        .title {
          height: 5%;
          background-color: rgba(0,0,0,0.6);
          border-radius: 6px 6px 6px 6px;
          text-align: center;
          margin-top: 12px;
            // margin: 10px 10px 10px 20px;
            width: 120%;
            padding: 10%;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            
          &.unit1 {
              background-color: mediumaquamarine;
              color: white;
          }
  
          &.unit2 {
              background-color: yellow;
              color: black;
          }
  
          &.unit3 {
              background-color: purple;
              color: white;
          }
  
          &.unit4 {
              background-color: lightblue;
              color: black;
          }
            &:hover {
              cursor: pointer;
              background-color: white;
              color: red;
              border-radius: 6px;
            }
        }
        .syllabus-days-container {
          width: 130%;
          background-color: rgba(37, 20, 3, 0.8);
          background-color: rgb(223, 29, 126);
          color: white;
          border-radius: 6px;
          padding: 5%;
          margin-top: -20px;
         
        }
    }
}