
.comment-block {

    margin-top: 12px !important;

    &.comment-text {
        font-family: 'Martel', serif;
        font-size: 18px; 
    }
}

