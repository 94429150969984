.comment {
    background-color: rgba(255,255,255,0.6);
    border-radius: 6px;
    max-width: 400px;
    margin-left: 20px;
   
    padding: 50px;

    @media screen and (max-width: 700px){
        margin-top: 20px;
        margin-left: 0px;
    }
}