


.day-overview-section {


    background-color: rgb(240, 235, 235);
    border: 5px solid rgba(154, 205, 50, 0.7);
    border-radius: 6px;
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    
    
    .content {

        background-color: #c67204;
        width: 55%;
        min-width: 300px;
        border-radius: 6px;
        padding: 20px;
        color: white;
        flex: 1 0 auto;

        @media screen and (max-width: 600px) {
            // background-color: white;
            width: 95%;
            flex: 0 1 auto;
        }



        .topline {
            display: flex;
            
            margin-top: -10px; 
           
        }
    
        .unit-button {
            background-color: black;
            color: white;
            padding: 6px;
            height: 40px;
            font-size: 24px;
            border-radius: 6px;
            display: inline-block;
    
            &.unit1 {
                background-color: mediumaquamarine;
                color: white;
            }
    
            &.unit2 {
                background-color: yellow;
                color: black;
            }
    
            &.unit3 {
                background-color: purple;
                color: white;
            }
    
            &.unit4 {
                background-color: lightblue;
                color: black;
            }
    
    
            &:hover {
                background-color: darkgrey;
                color: black;
                cursor: pointer;
            }
        }
    
        .title {
            // background-color: black;
            font-size: 30px;
            padding: 25px;
            margin-top: -25px;
            margin-left: 6px;
            // width: 50%;
            // border-radius: 6px;
            border-bottom: 1px solid white;
        }

        .day-description {
            font-family: 'Martel', serif;
            font-weight: 300;
            margin-top: 0px;
            padding: 20px;
            background-color: rgb(161, 109, 12);
            border-radius: 6px;

            .description-border{
                background-color: rgb(82, 12, 12);
                border: 1px solid white;
                padding: 30px;
                border-radius: 6px;

                .illustration {
                    width: 40%;
                    min-width: 100px;
                    max-width: 200px;
                    margin-left: 40px;
                    float: right;
                    
                }

            }
        }

        .assignment-day {
            background-color: rgb(220, 135, 220);
            //  
            color: white;
            padding: 24px;
            margin-top: 12px;
            border-radius: 6px;
            font-size: 15px;
            font-family: Lato;

            .assignment-heading {
                width: 100%;
                padding: 12px;
               
                color: rgb(126, 64, 6);
                margin-bottom: 12px;
            }

            .boundary-div {
                border-top: 3px solid brown;
                margin-top: 12px;
                margin-bottom: 24px;
            }

            .assignment-body {
                color: white;
                margin-bottom: 24px;;
                
            }
        }
    }

    .links-section{

        width: 35%;
        @media screen and (max-width: 600px) {
            // background-color: white;
            width: 95%;
            flex: 0 1 auto;
        }

        .links-title {
            border-bottom: 1px solid grey;
        }

        .links-list {
            flex: 1 2 auto;
            font-size: 14px;
            // width: 60%;
            list-style-type: none;
            background-color: lightyellow;
            padding: 10px;
            margin-left: 20px;
            border-radius: 6px;
    
            .day-overview-link {
                padding-left: 6px;
                
                &:hover {
                background-color: grey;
                padding: 10px;
                border-radius: 6px;
               
                a{
                    color: white;
                }
                
                }
            }
    }

    




    }

    .to-videos {
        border-radius: 6px;
        border: 3px solid chartreuse;
        margin-left:10%;
        padding: 10%;
        &:hover{
            background-color: white;
            cursor: pointer;
        }
        }




}