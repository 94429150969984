.resource-section{

    background-color: grey;
    padding: 1.6em;
   min-width: 200px;
    width: 30%;
    margin-top: 1em;
    margin-left: 1em;
    border-radius: 3px;
    display: inline-block;
    box-shadow: 3px 1px 10px darkolivegreen ;

    @media screen and(max-width:  800px){
        width: 45%;
    }

    @media screen and(max-width:  600px){
        width: 90%;
    }

    .links-list {
        flex: 1 2 auto;
        font-size: 14px;
        // width: 60%;
        padding: 0.1em;
        list-style-type: none;
        // background-color: lightyellow;
        // padding: 10px;
        // margin-left: 20px;
        border-radius: 0px;
        
    
        .resource-link {
            padding-left: 2em;
            border-radius: 0px;
            border-bottom: 1px solid lightblue;
           
            
            a{
                color: red;
            }

            &:hover {
            background-color: grey;
            padding: 10px;
            border-radius: 0px;
            // box-shadow: 1px 1px 3px 1px lightgrey ;
                a{
                color: white;
                }

            
            }
        }
}



}